// src/Footer.js
import React from 'react';
import './Footer.css';  // Corresponding CSS for footer styling

const Footer = () => {
    return (
        <footer className="footer">
            <p>&copy; 2025 MedicLedger. All Rights Reserved.</p>
            <ul className="social-links">
                <li><a href="#">Facebook</a></li>
                <li><a href="#">Twitter</a></li>
                <li><a href="#">LinkedIn</a></li>
            </ul>
        </footer>
    );
}

export default Footer;

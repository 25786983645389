import React, { useState } from 'react';
import './HomePage.css';  // Styling for the homepage
import regulatoryImage from '../../assets/regulatory.jpg';
import monitoring from '../../assets/monitoring.png';
import tracebility from '../../assets/tracebility.png';
import { useNavigate } from 'react-router-dom';
import biotechayur from '../../assets/biotechayur.png';
import ssnay from '../../assets/ssnay.png';
import university2 from '../../assets/university2.png';
import university3 from '../../assets/university3.png';
import university4 from '../../assets/university4.png';

const HomePage = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const navigate = useNavigate(); // Initialize navigate

    const handleSignUpRedirect = () => {
        navigate("/signup"); // Navigate to the SignUp page
    };
    const handleAiAssistantRedirect = () => {
        navigate("/ai-assistant"); // Redirect to the AI Assistant page
    };
    return (
        <div className="home">
            <section className="hero">
                <div className="hero-content">
                    <h1>Transforming the Herbal Supplement Supply Chain with Blockchain</h1>
                    <p>Boosting transparency, quality, and trust in every step of the process</p>
                    <div className="button-container">
                        <button onClick={handleSignUpRedirect} className="cta-button">Get Started</button>
                        <button className="secondary-button" onClick={handleAiAssistantRedirect}>AI Assistant</button>
                        {/* <button className="ai-assistant-btn" >AI Assistant</button> */}

                    </div>

                </div>
            </section>
            <section className="demo-section">
                <h2>Do you want to know how it works?</h2>
                <p>Interested in a hands-on demonstration? Get in touch with us to schedule a demo and see how MedicLedger can help you streamline your supply chain.</p>
                <button className="demo-btn" onClick={openModal}>Request a Demo</button>
            </section>

            <section id="how-it-works" className="how-it-works">
                <h2>How MedicLedger Works</h2>
                <div className="cards-container">
                    <div className="card">
                        <h3>Seamless Onboarding</h3>
                        <p>Easily onboard your products, track their details, and ensure compliance with industry standards.</p>
                        <a href="/onboarding" className="learn-more-btn">Learn More</a>
                    </div>
                    <div className="card">
                        <h3>Track in Real-Time</h3>
                        <p>Gain complete transparency over your products’ journey across the supply chain with real-time tracking.</p>
                        <a href="/tracking" className="learn-more-btn">Learn More</a>
                    </div>
                    <div className="card">
                        <h3>Simplify Compliance</h3>
                        <p>Automate regulatory compliance and inventory maintenance to stay ahead of quality control and legal standards.</p>
                        <a href="/compliance" className="learn-more-btn">Learn More</a>
                    </div>
                </div>
            </section>

            <section id="features" className="features">
                <h2>Key Features</h2>
                <div className="feature-section">
                    <div className="feature-item">
                        <img src={tracebility} alt="End-to-End Traceability" className="feature-icon" />
                        <div className="feature-text">
                            <h3>End-to-End Traceability</h3>
                            <p>Track your products from farm to consumer with full transparency.</p>
                        </div>
                    </div>
                    <div className="feature-item">
                        <img src={regulatoryImage} alt="Regulatory Compliance" className="feature-icon" />
                        <div className="feature-text">
                            <h3>Regulatory Compliance</h3>
                            <p>Automate compliance with FDA and FSSAI standards.</p>
                        </div>
                    </div>
                    <div className="feature-item">
                        <img src={monitoring} alt="Real-Time Monitoring" className="feature-icon" />
                        <div className="feature-text">
                            <h3>Real-Time Monitoring</h3>
                            <p>Monitor inventory, sales, and supply chain in real-time.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="partners" className="partners">
                <h2>Our Partners & Collaborators</h2>
                <p>We are proud to collaborate with leading partners and universities to transform the herbal supplement supply chain.</p>
                <div className="partner-logos">
                    <div className="partner-logo">
                        <img src={biotechayur} alt="Partner 1" />
                    </div>
                    <div className="partner-logo">
                        <img src={ssnay} alt="Partner 2" />
                    </div>
                    <div className="partner-logo">
                        <img src={university2} alt="Partner 3" />
                    </div>
                    <div className="partner-logo">
                        <img src={university3} alt="University 1" />
                    </div>
                    <div className="partner-logo">
                        <img src={university4} alt="University 2" />
                    </div>

                </div>
            </section>

            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <h2>Request a Demo</h2>
                        <form>
                            <input type="text" placeholder="Name" required class="form-field" />
                            <input type="email" placeholder="Email" required class="form-field" />
                            <input type="tel" placeholder="Contact Number" required class="form-field" />
                            <select class="form-field">
                                <option value="">Select Profession</option>
                                <option value="manufacturer">Manufacturer</option>
                                <option value="supplier">Supplier</option>
                                <option value="doctor">Doctor</option>
                            </select>
                            <input type="date" required class="form-field" />
                            <button type="submit" class="submit-button">Submit Request</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}

export default HomePage;
